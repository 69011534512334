import React from "react"
import styles from "./styles.module.scss"

const Fingers: React.SFC = () => (
  <g id="fingers">
    {fingerData.map((data, index) => (
      <path
        key={index}
        className="finger"
        d={data.d}
        transform={data.transform}
        fill="#F3E6D5"
      />
    ))}
    )}
  </g>
)

const fingerData = [
  {
    d:
      "M 12.1696 3.40922L 9.92116 13.5L 6.94743 10.3637L 8.16966 5C 8.16966 5 4.88566 3.93217 0.461006 3.40922C 0.461006 3.40922 -1.72438 0 3.42117 0C 10.5776 0 12.1696 3.40922 12.1696 3.40922Z",
    transform: "translate(154.33 -1337.5)",
  },
  {
    d: "M 9.0002 0L 11.5 6L 7.5 23L 0 19.5L 9.0002 0Z",
    transform: "translate(157 -1334.5)",
  },
  {
    d:
      "M 1.34885 6.75007L 14.6934 7.1333e-05L 27 9.00007L 27 12.0001L 14.6934 3.50007C 14.6934 3.50007 8.82008 6.75007 3.09215 9.00007C -2.63577 11.2501 1.34885 6.75007 1.34885 6.75007Z",
    transform: "translate(135 -1332)",
  },
  {
    d:
      "M 1.34885 6.75007L 14.6934 7.1333e-05L 27 9.00007L 27 12.0001L 14.6934 3.50007C 14.6934 3.50007 8.82008 6.75007 3.09215 9.00007C -2.63577 11.2501 1.34885 6.75007 1.34885 6.75007Z",
    transform: "translate(135 -1338)",
  },
  {
    d:
      "M 5.34663 7C 10.0088 4.20106 13.5266 0 13.5266 0L 33 10.0625L 32.1345 14L 13.5266 4.375C 13.5266 4.375 9.82629 5.49634 2.9029 12.7156C 1.67113 14 -4.1738 12.7156 5.34663 7Z",
    transform: "translate(135 -1327)",
  },
  {
    d:
      "M 7.71413 5.77437C 13.902 4.65589 18.2107 2.09977 18.2107 2.09977L 28 0L 28 7.08672L 18.2107 4.72448C 18.2107 4.72448 14.3089 5.39751 7.0069 9.72829C 5.24353 10.7741 -8.25885 8.66155 7.71413 5.77437Z",
    transform: "translate(137 -1320)",
  },
  {
    d:
      "M 9.49513 0L 21 9.23077L 19.9146 12L 9.49512 3.46153C 9.49512 3.46153 0.315414 8.3077 2.33172 7.15384C 2.33172 7.15384 -1.89132 3.46154 1.02918 3.46154C 5.09101 3.46154 9.49513 0 9.49513 0Z",
    transform: "translate(140 -1363)",
  },
  {
    d:
      "M 1.28921 4.08061L 12.4767 0L 25 5.17903L 24.1935 8L 12.4767 2.6404C 12.4767 2.6404 8.43017 4.08061 2.95545 6.24093C -2.51927 8.40126 1.28921 4.08061 1.28921 4.08061Z",
    transform: "translate(136 -1358)",
  },
  {
    d:
      "M 5.01296 0.70018C 8.8891 -0.455065 8.74093 0.167533 8.74093 0.167533L 24.4641 4.77622L 24.4641 6.77285L 11.3555 3.58681C 11.3555 3.58681 7.31675 1.31102 3.98781 4.56653C 2.88322 5.64676 -4.9927 3.68227 5.01296 0.70018Z",
    transform: "matrix(0.9666 -0.256291 0.256291 0.9666 141 -1351.73)",
  },
  {
    d:
      "M 1.53497 9.71126C 4.72989 7.6395 7.0222 10.5389 7.0222 5.63461C 7.0222 0.730371 8.71051 0 8.71051 0L 12.9084 1.80986C 12.9084 1.80986 13.0136 2.85747 11.3573 6.49057C 9.00823 11.643 1.1239 11.667 1.1239 11.667C 1.1239 11.667 -1.65996 11.783 1.53497 9.71126Z",
    transform: "matrix(0.974638 0.223788 -0.223788 0.974638 152.177 -1355.81)",
  },
]

export default Fingers
